<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">母亲节福利来了！珠宝玉石免费检测鉴定活动——3天！</div>
      <p class="time">2020.05.09</p>
      <p class="time" style="font-weight: 500;color: black">
        母亲节到了
      </p>
      <p class="time" style="font-weight: 500;color: black">
        历思又来给大家送福利啦
      </p>
      <p class="time" style="font-weight: 500;color: black">
        只要大家在公众号后台
      </p>
      <p class="time" style="font-weight: 500;color: black">
        发送一句“母亲节快乐，我来历思领福利”的口号
      </p>
      <p class="time" style="font-weight: 500;color: black">
        就可领取一份免费检测
      </p>
      <p class="time" style="font-weight: 500;color: black">
        鉴定珠宝玉石的福利哦！
      </p>
      <p class="time" style="font-weight: 500;color: black">
        翡翠、玉器、钻石、贵金属等
      </p>
      <p class="time" style="font-weight: 500;color: black">
        各类珠宝玉石均可检测
      </p>
      <p class="time" style="font-weight: 500;color: black">
        活动3天（5.10-5.12）
      </p>
      <p class="time" style="font-weight: 500;color: black">
        总共20个名额
      </p>
      <p class="time" style="font-weight: 500;color: black">
        一人一次最多可带贵金属、珠宝各一件
      </p>
      <p class="time" style="font-weight: 500;color: black">
        参加免费活动
      </p>
      <p class="time" style="font-weight: 500;color: black">
        5.12（周二）我们统一进行鉴定哦
      </p>
      <p class="time" style="font-weight: 500;color: black">
        名额有限
      </p>
      <p class="time" style="font-weight: 500;color: black">
        赶快来预约！
      </p>
      <p class="time" style="font-weight: 500;color: black">
        也可以电话预约哦！
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/29-1.png')">
      </div>
      <p class="time" style="font-weight: 500;color: black">
        地址：厦门湖里大道33号7层（联发集团大门保安亭旁）
      </p>
      <p class="time" style="font-weight: 500;color: black">
        电话：0592-2283132/18959633132
      </p>
      <p class="time" style="font-weight: 500;color: black">
        官网：
        <a style="text-align: left; word-break: break-all;
        word-wrap: break-word;
"
           href="http://www.lisitech.com">
          http://www.lisitech.com
        </a>

      </p>






    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/28'"><p class="ellipsis1">庆贺！厦门CA与历思科技正式建立战略合作伙伴关系！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/30'"><p class="ellipsis1">快看，历思“智慧禁毒”黑科技，上电视台啦~</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
